import { KoreanGroup, JPGroup, NAGroup, EUGroup } from '../constants/adGroups.const';

const regionGroupMapper = {
  KR: KoreanGroup,
  JP: JPGroup,
  US: NAGroup,
  EU: EUGroup,
};

/**
 * Checks to see if a user has only the warehouse ad group and not any of the
 * other ad groups that are currently allowed to submit returns in Korea.
 *
 * @param {array} groups - list of ad groups.
 * @returns true if user has only the ad group for warehouse workers and no other korean ad groups.
 */

export const hasAndIsKoreanWarehouseWorkerOnly = (groups) => {
  if (!groups || !Array.isArray(groups)) return false;
  const filteredGroups = groups.filter((group) => KoreanGroup.includes(group));
  return filteredGroups.length === 1 && filteredGroups[0] === 'App.ConsumerServices.Warehouse.KR';
};

export const hasAndIsWarehouseWorkerOnly = (groups, region) => {
  if (!groups || !Array.isArray(groups)) return false;
  const filteredGroups = groups.filter((group) => regionGroupMapper[region]?.includes(group));
  return (
    filteredGroups.length === 1 && filteredGroups[0] === `App.ConsumerServices.Warehouse.${region}`
  );
};
