/*
  Below is a list of permissions that an ahtlete can have.
  This is determined by the permissions table located in the database.
  Values for this can also be adjusted via the settings page through
  the UI and is used/determined based on their ad group.
 */
export const CreateReturn = 'Create Return';
export const CancelOrders = 'Cancel Orders';
export const MarkAsGiftReturn = 'Mark As Gift Return';
export const RefundToGiftCard = 'Refund to Gift Card';
export const SendResendReturnLabel = 'Send And Resend Return Label';
export const Developer = 'Is a Member of the Development Team';
export const ReturnGiftCard = 'Return Gift Card';
export const InspectReturn = 'Inspect Return';
export const ReinspectReturn = 'Reinspect Return';
export const ModifyPrice = 'Modify Price';
export const DiscountShipping = 'Discount Shipping';
export const ExchangeProduct = 'Exchange Product';
export const EditCommunicationMethod = 'Edit Communication Method';
export const LinkOrUnlinkOrder = 'Link Or Unlink Order';
export const RecalculateTax = 'Recalculate Tax';
export const AddPaymentMethod = 'Digital Add Payment Method';
export const SubmitPayment = 'Digital Submit Payment';
export const SuspendOrActivatePayment = 'Digital Suspend Or Activate Payment';
export const EditPaymentInfo = 'Edit Payment Info';
export const ResendEmail = 'Resend Email';
export const AddComment = 'Add Comment';
export const ViewFullGiftCardNumber = 'View Full Gift Card Number';
export const ViewPidReviewLink = 'View PiD Review Link';
export const GiftCardAccess = 'Gift Card Access';
export const ViewPromoCodes = 'View Promo Code Link';
export const ViewMemberProfile = 'View Member Profile';
export const ReturnAndCancelO2O = 'O2O Returns and Cancels';
export const MarkOpenPaymentsAsClosed = 'Mark Open Payments as Closed';
export const CreatePhysicalRfo = 'Digital Create New Physical Gift Card (RFO)';
export const CreateDigitalRfo = 'Digital Create New Digital Gift Card (RFO)';
export const ViewCloudPaymentTab = 'View Cloud Payment Tab';
export const ViewCspPaymentTab = 'View CSP Payment Tab';
export const CheckGCBalanceWithoutPin = 'Check GC Balance Without PIN';
export const ResendInvoice = 'Resend Invoice';
export const RetailSubmitPayment = 'Retail Submit Payment';
export const RetailSuspendOrActivatePayment = 'Retail Suspend Or Activate Payment';
export const RetailAddPaymentMethod = 'Retail Add Payment Method';
export const RetailCreatePhysicalRfo = 'Retail Create New Physical Gift Card (RFO)';
export const RetailCreateDigitalRfo = 'Retail Create New Digital Gift Card (RFO)';
export const RetailViewPaymentDetails = 'Retail View Payment Details';
export const ModifyPricePercentOff = 'Modify Price Percent Off';
export const ModifyPriceAmountOff = 'Modify Price Amount Off';
export const ByPassAddressValidation = 'ByPass Address Validation';

// This is the response value of a hasModificationPermission call.
export const Granted = 'granted';

export const L1AthleteADGroups = {
  NIKEUS: 'App.ConsumerServices.L1.US',
  NIKEEUROPE: 'App.ConsumerServices.L1.EU',
  NIKECN: 'App.ConsumerServices.L1.CN',
  NIKEJP: 'App.ConsumerServices.L1.JP',
  NIKEKR: 'App.ConsumerServices.L1.KR',
  NIKEAU: 'App.ConsumerServices.L1.AU',
};
