import getArrayProperty from './getArrayProperty';
import { discounts } from '../constants/chargeCategory.const';
import Geo from '../constants/geos.const';
import { inspectionStatusCodes, reInspectionStatusCodes } from '../constants/statusCodes.const';
import { stitchName } from './name';
import {
  Partners,
  PartnersExcludedFromDetailsInEMEA,
  EmeaPartnersAllowingReturnLabel,
  EmeaPartnersMasqueradingAsGeos,
} from '../constants/origin.const.js';
import { nestVasOrderLines } from './orderDetailDecoration.js';
import cloneDeep from 'lodash/cloneDeep';
import {
  LineItemInstructionType,
  BomColorType,
  BomPidTextFieldMatcher,
} from '../constants/order.const';

/**
 * a function which takes an orderType and formats it for display on order detail page
 * example: input 'RETURN_ORDER' returns 'Return Order'
 * @param {String} orderType
 */
export const formatOrderType = (orderType) => {
  if (typeof orderType === 'string') {
    return orderType
      .toLowerCase()
      .replace('_', ' ')
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' ');
  }
};

/**
 * Returns displaySize if available or else size description
 * @param {Object} line - orderLine
 * @returns String - size of the product
 */
export const getDisplaySize = (line) => {
  if (line?.displaySize && line?.displaySize !== 'N/A') {
    return line?.displaySize;
  }
  return line?.sizeDescription;
};

/**
 * Returns gift message if it is available as a line instruction
 * @param {Object} line - orderLine
 * @returns String - gift message
 */
export const getGiftMessage = (line) => {
  const instruction = line?.instructions?.find(
    (instruction) => instruction.instructionType === LineItemInstructionType.GIFT_MESSAGE
  );
  return instruction?.detail ?? null;
};

/**
 * a function to capitalize string values.
 * example:
 *  - 'COLOR' returns 'Color'
 * @param {String} input string to capitalize
 * @returns { String }
 */
const capitalizeText = (str) => {
  return str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase();
};

/**
 * a function to rename/format known BOM field names to more meaningful names.
 * @param {String} prefix string to format
 * @returns { String }
 */
const formatPrefix = (fieldType) => {
  if (fieldType === 'ID') return 'Text';
  return capitalizeText(fieldType);
};

/**
 * a function to convert BOM labels and values to title case format.
 * examples:
 *  -'COLLAR/ TONGUE LINING' returns 'Collar/ Tongue Lining'
 *  -'TIP/EYESTAY/FOXING/BACKSTAY' returns 'Tip/EyeStay/Foxing/Backstay'
 * @param {String} bomString
 * @returns { String }
 */
export const formatBomString = (bomString) => {
  if (typeof bomString === 'string') {
    return bomString
      .split(' ') // Split up words using space separator.
      .map((str) => {
        // Condition to avoid formatting hex codes for colors.
        if (Number(str.charAt(0))) {
          return str;
        }
        // Create title case string for each iteration.
        const titleCaseStr = capitalizeText(str);
        // Parse this new titleCaseStr so characters following a '/' can be uppercase.
        const parsedForSlashesStr = titleCaseStr
          .split('/') // Split up the title case string using '/' separator.
          .map(
            (reSplitStr) => capitalizeText(reSplitStr) // Return title case String for each iteration.
          )
          .join('/'); // Reassemble title case string separated by '/'.
        return parsedForSlashesStr;
      })
      .join(' '); // Reassemble full string from space (' ') separated array.
  }
};

/**
 * a function to assemble the string for one BOM value.
 * @param {array} bomValueArr Array of objects for a given BOM value.
 * @returns {string}
 */
export const formatBomValue = (bomValueArr) => {
  if (Array.isArray(bomValueArr)) {
    let bomStr = '';
    for (let i = 0; i < bomValueArr.length; i++) {
      /**
       * if any given BOM value array has a `COLOR` field,
       * or has PID name as `DEFAULT - ABC`, `DEFAULT PID - ABCDEFG` etc. then
       * display the field type as a prefix to the field value
       */
      const prefix =
        bomValueArr[i].type.toLowerCase() === BomColorType ||
        bomValueArr[i].name.toLowerCase().match(BomPidTextFieldMatcher)
          ? `${formatPrefix(bomValueArr[i].type)}:`
          : '';

      bomStr += `${prefix} ${bomValueArr[i].text ?? bomValueArr[i].name}`;
      if (i !== bomValueArr.length - 1) {
        bomStr += `${prefix ? ', ' : ' '}`;
      }
    }
    return bomStr;
  }
  return typeof bomValueArr === 'string' ? bomValueArr : '';
};

/**
 * a function which takes an order line and returns either the
 * most recent status therein, or an empty string
 *
 * @param {Object} line – the order line from which the status will be pulled
 */
export const getOrderlineStatusCode = (line) => {
  // chain up the object tree to avoid a TypeError
  const status =
    Boolean(line) &&
    Boolean(line.statuses) &&
    Boolean(line.statuses.length) &&
    line.statuses[line.statuses.length - 1].statusCode;

  return status || '';
};

/**
 * a function which takes an order line and returns either the
 * most recent status therein, or an empty string
 *
 * @param {Object} line – the order line from which the status will be pulled
 */
export const getOrderlineStatusDescription = (line) => {
  // chain up the object tree to avoid a TypeError
  const status =
    Boolean(line) &&
    Array.isArray(line.statuses) &&
    Boolean(line.statuses.length) &&
    line.statuses[line.statuses.length - 1].description;

  return status || '';
};

/**
 * Gets the parentLineNumber from the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number|*}
 */
export const getOrderLineParentLineNumber = (orderLine) => {
  let parentLineNumberReferences =
    Array.isArray(orderLine?.references) &&
    orderLine.references.filter((orderLineReference) => {
      return orderLineReference.name === 'ParentLineNo';
    });
  return (
    parentLineNumberReferences &&
    parentLineNumberReferences.length &&
    // There should only be one, so grab the member at the first index.
    parentLineNumberReferences[0].value
  );
};

/**
 * This util function checks if an order is for a Swoosh user by checking if
 * any of the payment methods contain the Swoosh merchant account number
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isSwooshOrder = (orderDetail) => {
  // this value is based on data derived from testing
  const swooshMerchantCode = 'swooshusa';
  if (!orderDetail) return false;

  const paymentMethods = getArrayProperty(orderDetail, 'paymentMethods');

  return paymentMethods
    .map((paymentMethod) => paymentMethod?.merchantAccountNumber)
    .includes(swooshMerchantCode);
};

/**
 * Creates containers based on the orderLines provided. The containers will have references to the
 * item and a service/vas item, if there is one associated with it.
 *
 * @param {array} orderLines OrderLine objects.
 */
export const getOrderLineContainers = (orderLines, omsRegionReference) => {
  const result = [];

  if (Array.isArray(orderLines)) {
    const items = orderLines.filter((line) => line.orderLineType !== 'SERVICE');

    for (let i = 0; i < items.length; i++) {
      let orderLineContainer = {
        item: null,
        service: null,
      };
      const inlineItem = items[i];

      orderLineContainer.item = inlineItem;

      if (inlineItem.hasNestedVasOrderLines) {
        // Vas items in the US geo cannot be cancelled or returned.
        let matchingServiceItem = inlineItem.nestedVasOrderLines[0];
        if (omsRegionReference === Geo.US) {
          matchingServiceItem['returnableFlag'] = false;
          matchingServiceItem['cancellableFlag'] = false;
          matchingServiceItem['returnableQuantity'] = 0;
          matchingServiceItem['cancellableQuantity'] = 0;
        }
        orderLineContainer['service'] = matchingServiceItem;
      }

      result.push(orderLineContainer);
    }
  }

  return result;
};

/**
 * Determine if the current order should have Return Label functionality enabled
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 * @return {boolean}
 */
export const determineIfOrderAllowsReturnLabel = (orderDetail) => {
  const { omsRegionReference: geo } = orderDetail;
  if (geo !== Geo.EUROPE && !EmeaPartnersMasqueradingAsGeos.has(geo)) {
    /* 
      We return false for orders in China, Japan, Korea, where Return Label
      creation is not enabled. We return true for other non-EMEA regions (e.g. U.S.).
    */
    return ![Geo.CHINA, Geo.JAPAN, Geo.KOREA].includes(geo);
  }
  const emeaPartner = getEmeaPartner(orderDetail);

  if (!emeaPartner) return true; // EMEA non-partner order allows for Return Label creation.

  // If EMEA partner is JD Sports...
  const jdSportsStrings = new Set([Geo.JDSPORTS, 'jdsports.digital.web']);
  if (jdSportsStrings.has(emeaPartner)) {
    // ...we return true for orders NOT in the UK
    return orderDetail.orderLines?.every((ol) => ol?.shipTo?.address?.country !== 'GB');
  }
  // If EMEA partner is NOT JD Sports, we return true only if partner's on the allow-list
  return EmeaPartnersAllowingReturnLabel.has(emeaPartner);
};

/**
 * Determine if the order is eligible for canceling. CancellableQty is sufficient for all GEOs
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsCancellable = (orderDetail) => {
  return isOrderLevelCancellable(orderDetail) && checkOrderLinesCancellability(orderDetail);
};

export const isOrderLevelCancellable = (orderDetail) => {
  if (Partners[orderDetail?.channel] || orderDetail?.pendingModification) {
    return false;
  }
  return true;
};

/**
 * Determine if the order is eligible for returning.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsReturnable = (orderDetail) => {
  let isReturnable = false;
  if (
    !Boolean(orderDetail) ||
    !Array.isArray(orderDetail.orderLines) ||
    // if it's an EMEA jdsports partner order in UK, it's not returnable
    // unfortunately EMEA jdsports orders still have omsRegionReference 'JDSPORTS'
    (Partners[orderDetail?.channel] && isJdSportsUkOrder(orderDetail)) ||
    // if it's a jdsports partner order NOT in EMEA, it's not returnable
    // EMEA is the only geo with sales orders with omsRegionReference 'JDSPORTS' (US has returns)
    (orderDetail?.channel === 'jdsports.digital.web' &&
      orderDetail?.omsRegionReference !== Geo.EUROPE &&
      orderDetail?.omsRegionReference !== Geo.JDSPORTS) ||
    /*
      if it's a non-EMEA partner order, it's not returnable
      NOTE-1: the `Partners` list includes non-EMEA partners, with the exception of jdsports.
      NOTE-2: we add the !jdsports condition because jdsports partner exists both in and
      out of EMEA. returns are allowed for this partner in emea (-uk), but not outside of emea
    */
    (Partners[orderDetail?.channel] && orderDetail?.channel !== 'jdsports.digital.web')
  )
    return isReturnable;

  const { orderLines } = orderDetail;

  for (const orderLine of orderLines) {
    if (orderLine?.omoboFlags?.isReturnable) {
      isReturnable = true;
    }
  }
  return isReturnable;
};

/**
 * Determine if the selected line items are digital gift cards
 *
 * @param {Object} selectedLines - Object of selected line items for return or cancel.
 */
export const areLineItemsDigitalGiftCards = (selectedLines) => {
  return Object.values(selectedLines).every(
    (sl) => sl.styleNumber === 'GIFTCARD' && sl.fulfillmentMethod === 'DIGITAL'
  );
};

/**
 * Returnable logic taken from the link here
https://confluence.nike.com/display/CE/Order+Review+V1+Rules?focusedCommentId=358893297
&refresh=1597771485220#comment-358893297
 *
 * I am not going to comment this function, because I don't know why. this logic was copied without
 * explanation
 * 
 * @param {object} orderLine – the order line to check returnability of
 */
export const isOrderLineReturnable = (orderLine) => {
  const { orderLineType, statuses, omoboFlags, returnableFlag = true } = orderLine;

  const isStoreOrder = orderLineType === 'STORE';

  if (orderLineType === 'NONMERCH') return false;

  if (orderLineType === 'SERVICE') return false;

  const orderStatuses = Array.isArray(statuses) ? statuses : [];
  const statusCodes = orderStatuses ? orderStatuses.map((status) => status.statusCode) : [];
  const maxLineStatus = statusCodes.sort()[statusCodes.length - 1];

  /*
    For non-bopis and non-store orders if all of the order lines are in non-returnable status
    then return false.
   */
  if (!omoboFlags.isBOPIS && !isStoreOrder) {
    const isAllOrderLinesNotEligibleForReturn = statusCodes?.every(
      (statusCode) => statusCode < '3700' || statusCode >= '9000'
    );
    if (isAllOrderLinesNotEligibleForReturn) return false;
  }

  // if the orderLine is BOPIS, only return true if the item has been picked up.
  if (omoboFlags.isBOPIS) return maxLineStatus === '3200.1400';
  const returnableQuantity = getReturnableQuantity(orderStatuses);
  // Disable the orderline if the returnable quantity is less than 0
  // For store orders, add additional check on the returnable flag.
  // eventually all logic in omobo should be changed to be based on this flag.
  return isStoreOrder ? returnableFlag !== false && returnableQuantity > 0 : returnableQuantity > 0;
};

/**
 * Based on orderStatuses with in the orderLines, determines the returnable quantity
 * @param {*} orderStatuses - Order status with in the orderLines
 */
export const getReturnableQuantity = (orderStatuses) => {
  const unreturnableStatuses = [
    '3700.01',
    '3700.011',
    '3700.02',
    '3700.03',
    '3700.04',
    '3700.05',
    '3700.06',
  ];

  const additionalReturnableStatuses = ['3200.1400', '1100.02'];

  /*
   This logic is based on Gagarin confluence page mentioned above
   Following actions are performed in the below block
   1. Filtering out order statuses that can't returned
   2. Getting the order quantity from orderStatus that can be returned
  */
  if (orderStatuses) {
    return orderStatuses
      .filter((orderStatus) => {
        return (
          (orderStatus.statusCode >= '3700' &&
            orderStatus.statusCode < 9000 &&
            !unreturnableStatuses.includes(orderStatus.statusCode)) ||
          additionalReturnableStatuses.includes(orderStatus.statusCode)
        );
      })
      .map((orderStatus) => {
        return parseInt(orderStatus.quantity);
      })
      .filter((quantity) => {
        return isFinite(quantity);
      })
      .reduce((totalQuantity, quantity) => {
        return totalQuantity + quantity;
      }, 0);
  }
};

/**
 * Determines if the given VAS orderLine is returnable.
 *
 * @param vasOrderLine {object} - The VAS orderLine
 * @returns {boolean} - Whether or not the VAS orderLine is returnable
 */
export const isVASOrderLineReturnable = (vasOrderLine) => {
  if (!vasOrderLine?.parentOrderLine?.omoboFlags?.isReturnable) return false;
  return isVASOrderLineJerseyId(vasOrderLine);
};

/**
 * Determines if the given VAS orderLine is a Jersey ID.
 *
 * @param vasOrderLine {object} - The VAS orderLine
 * @returns {boolean} - Whether or not the VAS orderLine is a Jersey ID
 */
export const isVASOrderLineJerseyId = (vasOrderLine) => {
  return vasOrderLine?.styleNumber === 'VAS0020';
};

/**
 * Determines if the given order is eligible for exchange.
 *
 * Ineligible items:
 * Value Added services (VAS) Items
 * Nike By You (NBY)
 * Voucher Product/items
 *
 * Exchange button should only be activated when the return order is in "Created" status
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsExchangeable = (orderDetail) => {
  const { orderType, status, orderLines } = orderDetail;

  // must be return order synced with DOMS in created status or delivered to return center status
  if (
    orderType !== 'RETURN_ORDER' ||
    (status.toLowerCase() !== 'created' && status.toLowerCase() !== 'delivered to return center') ||
    !orderDetail?.orderHeaderKey
  )
    return false;

  let formattedOrderLines = orderLines;

  // if VAS orderLines exist, nest the VAS orderLines under their corresponding regular orderLines
  if (orderDetail.orderLines.some((orderLine) => orderLine.orderLineType === 'SERVICE')) {
    let regularOrderLines = orderLines.filter((orderLine) => orderLine.orderLineType !== 'SERVICE');
    let vasOrderLines = orderLines.filter((orderLine) => orderLine.orderLineType === 'SERVICE');
    nestVasOrderLines(regularOrderLines, vasOrderLines);
    formattedOrderLines = regularOrderLines;
  }

  // if all order lines are VAS, NBY, or Voucher then not eligible for exchange
  const isAnyLineEligible = !formattedOrderLines.every(
    (orderLine) =>
      orderLine.hasNestedVasOrderLines || // VAS
      orderLine.orderLineType === 'NIKEID' || // NBY
      orderLine.orderLineType === 'VOUCHER' // Voucher
  );

  return isAnyLineEligible;
};

/**
 * Determine if the order is eligible for inspection.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsInspectable = (orderDetail) => {
  if (orderDetail?.pendingModification) return false;
  return determineOrderEligibilityByLineStatus(orderDetail, inspectionStatusCodes);
};

/**
 * Determine if the order is eligible for re-inspection.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsReInspectable = (orderDetail) => {
  if (orderDetail?.pendingModification) return false;
  return determineOrderEligibilityByLineStatus(orderDetail, reInspectionStatusCodes);
};

/**
 * Determines whether any of an order detail's orderlines are in a cancellable state.
 * @param {object} orderDetail Order detail containing orderlines.
 * @returns {boolean} true if any of the orderLines is cancellable, false otherwise.
 */
export const checkOrderLinesCancellability = (orderDetail) => {
  // make sure we have the basics needed for determining eligibility
  if (!Boolean(orderDetail) || !Array.isArray(orderDetail.orderLines)) return false;
  const { orderLines } = orderDetail;
  if (!orderLines) return false;

  for (let line of orderLines) {
    if (isOrderLineCancellable(line)) {
      return true;
    }
  }

  return false;
};

/**
 * Checks to see whether an orderLine is cancellable based on the cancellable
 * quantity and the existing amount of cancel requests currently in process
 * for a specific item.
 *
 * @param {object} orderLine an orderLine object from an orderDetail.
 * @returns {boolean} true if the orderLine is cancellable, false otherwise.
 */
export const isOrderLineCancellable = (orderLine) => {
  if (
    orderLine?.cancellableQuantity &&
    // cancelRequestQuantity checked this way because it may have the value of 0, which is falsy
    orderLine?.hasOwnProperty('cancelRequestQuantity') &&
    orderLine.cancellableQuantity > orderLine.cancelRequestQuantity
  ) {
    return true;
  }
  return false;
};

/**
 * Determines an order's eligibility for an action based on the status codes provided.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 * @param {array} eligibleStatusCodes - Array of string values of valid status codes.
 */
export const determineOrderEligibilityByLineStatus = (orderDetail, eligibleStatusCodes) => {
  if (!orderDetail || !Array.isArray(eligibleStatusCodes)) return false;
  const { orderLines } = orderDetail;
  if (!orderLines) return false;

  for (let line of orderLines) {
    const statusCode = getOrderlineStatusCode(line);
    if (eligibleStatusCodes?.includes(statusCode)) {
      return true;
    }
  }

  return false;
};

/**
 * Determines an order's eligibility for shipping discounts
 *
 * @param {object} orderDetail
 * @returns {boolean}
 */
export const canShippingBeDiscounted = (orderDetail) => {
  const { status, orderHeaderKey, pendingModification } = orderDetail;
  const headerCharges = getArrayProperty(orderDetail, 'headerCharges');

  let discountShippingAllowed = true;

  const isCancelled = status === 'Cancelled';
  const shippingCostIsZero = getHeaderShippingCharges(headerCharges) + getItemShippingCharges === 0;
  const isDiscountsMoreThanCharges =
    parseFloat(getHeaderDiscountCharges(headerCharges)) >=
    parseFloat(getNonDiscountHeaderCharges(headerCharges));
  const isNotSyncedToDOMS = !orderHeaderKey;
  const hasPendingModification = Boolean(pendingModification);

  if (
    isCancelled ||
    shippingCostIsZero ||
    isDiscountsMoreThanCharges ||
    isNotSyncedToDOMS ||
    hasPendingModification
  ) {
    discountShippingAllowed = false;
  }

  return discountShippingAllowed;
};

/**
 * Gets the total discounts value for the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getDiscount = ({ lineCharges = [], quantity = 0 } = {}) => {
  return Array.isArray(lineCharges)
    ? lineCharges
        .filter((charge) => discounts.includes(charge.chargeCategory))
        .map((charge) => charge.chargePerQuantity * quantity)
        .reduce((acc, cur) => acc + cur, 0)
    : 0;
};

/**
 * Gets all discounts information for the given orderLine
 *
 * @param orderLine {Object}
 * @returns {array}
 */
export const getDiscounts = ({ lineCharges = [] } = {}) => {
  return Array.isArray(lineCharges)
    ? lineCharges.filter((charge) => discounts.includes(charge.chargeCategory))
    : 0;
};

/**
 * Gets the total charges, for the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getCharges = ({ lineCharges = [] } = {}) => {
  return Array.isArray(lineCharges)
    ? lineCharges
        .filter((charge) => !discounts.includes(charge.chargeCategory))
        .map((charge) => charge.chargePerQuantity)
        .reduce((acc, cur) => acc + cur, 0)
    : 0;
};

/**
 * Gets the total shipping charges for the given order
 *
 * @param {array} headerCharges Array of header charges from orderDetail
 * @returns {number}
 */
export const getHeaderShippingCharges = (headerCharges = []) =>
  Array.isArray(headerCharges)
    ? headerCharges
        .filter(
          (charge) =>
            typeof charge?.chargeCategory === 'string' &&
            !charge?.chargeCategory?.includes('DISCOUNT') &&
            !charge?.chargeCategory?.includes('RETURNFEE')
        )
        .reduce((acc, cur) => acc + Number(cur?.chargeAmount || 0), 0)
    : 0;

export const getHeaderReturnShippingCharges = (headerCharges = []) =>
  Array.isArray(headerCharges)
    ? headerCharges
        .filter(
          (charge) =>
            typeof charge?.chargeCategory === 'string' &&
            charge?.chargeCategory?.includes('RETURNFEE')
        )
        .reduce((acc, cur) => acc + Number(cur?.chargeAmount || 0), 0)
    : 0;

/**
 * Gets the shipping charges for the given item.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getItemShippingCharges = ({ lineCharges = [] } = {}) => {
  // if there are any item level shipping charges, add only the ones that aren't discounts
  return Array.isArray(lineCharges)
    ? lineCharges
        .filter(
          (charge) =>
            charge?.chargeCategory === 'SHIPPING' && !charge?.chargeCategory?.includes('DISCOUNT')
        )
        .reduce((acc, lineCharge) => acc + lineCharge?.chargePerQuantity, 0)
    : 0;
};

/**
 * Gets the total shipping discounts for the given order
 *
 * @param {array} headerCharges Array of header charges from orderDetail
 * @returns {number}
 */
export const getHeaderShippingDiscounts = (headerCharges = []) => {
  return Array.isArray(headerCharges)
    ? headerCharges
        .filter(
          (charge) =>
            typeof charge?.chargeCategory === 'string' &&
            charge?.chargeCategory?.includes('DISCOUNT')
        )
        .reduce((acc, cur) => acc + Number(cur?.chargeAmount || 0), 0)
    : 0;
};

/**
 * Gets all discounts information for the given orderLine
 *
 * @param orderLine {Object}
 * @returns {array}
 */
export const getTaxDiscounts = ({ lineTaxes = [] } = {}) => {
  // if there are any tax discounts, add those together
  return Array.isArray(lineTaxes)
    ? lineTaxes
        .filter((tax) => tax.chargeCategory === 'DISCOUNT')
        .reduce((acc, taxDiscount) => acc + taxDiscount?.taxAmount, 0)
    : 0;
};

/**
 * Gets the tax amount for the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getTax = ({ lineTaxes = [] } = {}) => {
  // if there are any taxes, add only the taxes that aren't tax discounts
  return Array.isArray(lineTaxes)
    ? lineTaxes
        .filter((tax) => tax.chargeCategory !== 'DISCOUNT')
        .reduce((acc, lineTax) => acc + lineTax?.taxAmount, 0)
    : 0;
};

/**
 * Gets the Value Added Tax for the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getVatPrice = ({ lineTaxes = [] } = {}) => {
  return Array.isArray(lineTaxes)
    ? lineTaxes
        .filter((tax) => tax.taxName === 'VAT')
        .reduce((acc, lineTax) => acc + lineTax?.effectiveTaxAmount, 0)
    : 0;
};

/**
 * Gets the billing name by attempting to stitch a name from various objects in orderDetail
 * until it succeeds, or returns an empty string.
 * For a claim gift card order, the billing name is the shipTo recipient name of the orderLine.
 * this is
 * identified by orderClassification as ProductVoucher and orderLineType as EGC
 * @param {Object} orderDetail the orderDetail
 * @returns {string} the consumerName
 */
export const getBillingName = (orderDetail) => {
  return orderDetail?.orderClassification === 'ProductVoucher' &&
    orderDetail?.orderLines?.[0]?.orderLineType === 'EGC'
    ? getShippingNameForProductVoucher(orderDetail)
    : stitchName(orderDetail?.billTo?.recipient, orderDetail?.omsRegionReference) ||
        stitchName(orderDetail?.paymentMethods?.[0], orderDetail?.omsRegionReference) ||
        stitchName(
          orderDetail?.paymentMethods?.[0]?.billTo?.recipient,
          orderDetail?.omsRegionReference
        ) ||
        '';
};

/**
 * For product vouchers the consumer name is only available in the shipTo object
 * as the billing details is Nike specific
 */
export const getShippingNameForProductVoucher = (orderDetail) => {
  return (
    orderDetail.orderLines?.[0]?.shipTo?.recipient?.firstName +
    ' ' +
    orderDetail.orderLines?.[0]?.shipTo?.recipient?.lastName
  );
};

/**
 * Gets billing email by checking fields in the given orderDetail, or
 * returns an empty string.
 *
 * @param {Object} orderDetail the orderDetail
 * @returns {string} the consumerEmail
 */
export const getBillingEmail = (orderDetail) => {
  return orderDetail?.orderClassification === 'ProductVoucher' &&
    orderDetail?.orderLines?.[0]?.orderLineType === 'EGC'
    ? getEmailFromShipTo(orderDetail)
    : orderDetail?.billTo?.contactInformation?.email ||
        orderDetail?.paymentMethods?.[0]?.billTo?.contactInformation?.email ||
        '';
};

export const getEmailFromShipTo = (orderDetail) => {
  return orderDetail?.orderLines?.[0]?.shipTo?.contactInformation?.email || '';
};

/**
 * Gets billing phone provided in orderDetail or returns an empty string.
 *
 * @param {Object} orderDetail the orderDetail
 * @returns {string} the consumerPhone
 */
export const getBillingPhone = (orderDetail) => {
  return orderDetail?.billTo?.contactInformation?.dayPhoneNumber || '';
};

/**
 * Identifies sets of NikeID orderLines, removes all but one per
 * customizedProductReference (Metric ID / CPR) from top level orderLines array,
 * and stores the full set in a nested array under nikeIdLines.
 *
 * @param orderDetail {object} - The orderDetail to filter.
 */
export const filterNikeIDLines = (orderDetail) => {
  let { orderLines } = orderDetail;
  if (!Array.isArray(orderLines)) return [];
  const allNikeIdOrderLines = orderLines.filter((line) => line.orderLineType === 'NIKEID');

  // transforms all NikeId orderLines into a dictionary by CPR
  const reduceNikeIdOrderLines = (nikeIds, orderLine) => {
    if (Array.isArray(nikeIds[orderLine.customizedProductReference])) {
      nikeIds[orderLine.customizedProductReference].push(orderLine);
    } else {
      nikeIds[orderLine.customizedProductReference] = [orderLine];
    }
    return nikeIds;
  };
  const cPRDict = allNikeIdOrderLines.reduce(reduceNikeIdOrderLines, {});

  /**
   * Attaches identified sets of NikeId lines to the NikeId line with unitOfMeasure === 'EA',
   * drops all other NikeId lines from top level array, and leave all non-NikeId lines in place.
   */
  const attachNikeIds = (orderLines, line) => {
    if (line.orderLineType === 'NIKEID') {
      if (line.item?.unitOfMeasure === 'EA' || orderDetail.orderHeaderKey === null) {
        // attach matching dictionary set to the top level line we're keeping
        line.nikeIdLines = cPRDict[line.customizedProductReference];
        orderLines.push(line);
      }
      // if not an EA line, do not add to the accumulator
    } else {
      orderLines.push(line);
    }
    return orderLines;
  };

  // alter the original orderDetail object
  const orderLinesCopy = cloneDeep(orderLines);
  return orderLines.splice(0, orderLines.length, ...orderLinesCopy.reduce(attachNikeIds, []));
};

/**
 * This function takes in an array of orderLines, determines if there are cancelled orderLines
 * therein (or a quantity of cancelled items within an orderline)
 *
 * @param {Object} lines – the orderLines array from an order detail object
 * @returns {Array<array>} - [lines, cancelledLines], a separation of order lines arrays with
 * cancelled lines being on one side, and uncancelled on the other
 */
export const separateCancelledItems = (lines = []) => {
  // if we've encountered odd input, get outta here
  if (!Array.isArray(lines) || !lines.length) return [[], []];

  // first, let's make an array of the status codes
  const statusCodes = lines
    .map((line) => line.statuses)
    .flat()
    .map((status) => status?.statusCode);
  /*
   are some of the orderLines cancelled, status codes starting with 9000, as per
   confluence.nike.com/pages/viewpage.action?spaceKey=MOM&title=Order+Status+Mapping+for+Consumers
    */
  const someLinesCancelled = statusCodes?.some((code) => code?.startsWith('9000'));
  // are ALL of the lines cancelled?
  const allLinesCancelled = statusCodes?.every((code) => code?.startsWith('9000'));

  // if some of the lines are cancelled, we might need to separate some out
  if (someLinesCancelled) {
    // unless ALL of the lines are, in which case, let's short circuit out
    if (allLinesCancelled) {
      // no uncancelled order lines, as determined
      return [[], lines];
    }

    // instantiate arrays for our separate types
    const cancelledLines = [];
    const otherLines = [];
    // if we need a new lineNumber, let's make it. they're 1-indexed, so this should do.
    let newLineNumber = lines.length + 1;

    // iterate over the order lines
    for (const line of lines) {
      // make a new line object for each type of line (cancelled, and non), with clear statuses
      // a regular line object, with no cancellation reason
      const newLine = {
        ...line,
        statuses: [],
        cancellationReason: null,
      };
      // a cancelled line object, with the requisite details
      const cancelledLine = {
        ...line,
        statuses: [],
        // cancelled items are without quantity
        quantity: 0,
        // it should be its own order line
        lineNumber: newLineNumber,
        // it's not cancellable
        cancellableQuantity: 0,
        // nor should details from other order lines cary over
        refundReason: null,
        returnableFlag: false,
        returnableQuantity: 0,
        returnReason: null,
        // obvs
        cancellableFlag: false,
      };
      // loop over the statuses on each order line
      for (const status of line.statuses) {
        // if the status starts with 9000, marking it as a cancel (ref linked above)
        if (status.statusCode.startsWith('9000')) {
          // mark it as a cancelled line
          cancelledLine.statuses.push(status);
          // Setting cancelled lines to have the correct quantity
          cancelledLine.quantity = cancelledLine.statuses[0].quantity;
        } else {
          // otherwise, it's gonna belong in our regular order lines
          newLine.statuses.push(status);
        }
      }
      /*
       if we have statuses in either order type, that means that it represents a real order,
       as is dictated by the above.
       */
      if (newLine.statuses.length) {
        otherLines.push(newLine);
      }
      if (cancelledLine.statuses.length) {
        newLineNumber++;
        cancelledLines.push(cancelledLine);
      }
    }

    // a function to sort each array by order line numbers
    const sorter = (a, b) => (a.lineNumber > b.lineNumber ? 1 : -1);

    // return the two. sorted
    return [otherLines.sort(sorter), cancelledLines.sort(sorter)];
  }

  // this case, as dictated above, means that there are no cancelled items
  return [lines, []];
};

/**
 * Gets the total discounts on order header charges
 *
 * @param headerCharges {Object}
 * @returns {number}
 */
export const getHeaderDiscountCharges = (headerCharges) => {
  const discountChargesArray = getHeaderDiscountsArray(headerCharges);
  return Array.isArray(discountChargesArray)
    ? discountChargesArray.reduce((acc, cur) => acc + Number(cur.chargeAmount || 0), 0)
    : 0;
};

/**
 * Gets the total headers charges excluding discounts.
 *
 * @param headerCharges {Object}
 * @returns {number}
 */
export const getNonDiscountHeaderCharges = (headerCharges) => {
  const headerChargesArray = getNonDiscountHeaderArray(headerCharges);
  return Array.isArray(headerChargesArray)
    ? headerChargesArray.reduce((acc, cur) => acc + Number(cur.chargeAmount || 0), 0)
    : 0;
};

/**
 * Gets the headers charges excluding discounts.
 *
 * @param headerCharges {Object}
 * @returns {object}
 */
export const getNonDiscountHeaderArray = (headerCharges) => {
  return Array.isArray(headerCharges)
    ? headerCharges.filter((charge) => !discounts.includes(charge.chargeCategory))
    : [];
};

/**
 * Finding the athlete shipping discount info from an order notes.
 */
export const getAthleteCharges = (headerCharges) => {
  if (Array.isArray(headerCharges)) {
    const chargeObject = headerCharges.find((charge) => charge.chargeCategory === 'DISCOUNT');
    return chargeObject ? chargeObject.agentReference : 0;
  }
};

/**
 * Gets the headers charges that are discounts.
 *
 * @param headerCharges {Object}
 * @returns {object}
 */
export const getHeaderDiscountsArray = (headerCharges) => {
  return Array.isArray(headerCharges)
    ? headerCharges.filter((charge) => discounts.includes(charge.chargeCategory))
    : [];
};

/**
 * a function which takes a paymentStatus and formats it for display on order detail page
 * example: input 'AWAIT_AUTH' returns 'Await Auth'
 * @param {String} paymentStatus
 */
export const formatPaymentStatus = (paymentStatus) => {
  if (typeof paymentStatus === 'string') {
    return paymentStatus
      .toLowerCase()
      .replace('_', ' ')
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' ');
  }
};

/**
 * A) Removes shipped items without shipment info from itemsWithoutShipment array
 * B) For store orders, removes items not delivered to the store:
 * Imagine an order was made at a store that includes an item that is out of stock at the store
 * So it is going to be delivered from a warehouse directly to the customer (not to the store)
 * We wouldn't want that item to appear under the regular "Store Order" label and mislead an athlete
 * Instead, we remove these items from `itemsWithoutShipment` and return them in a new array
 * @param {OrderLine} itemsWithoutShipment - order line with no shipment info
 * @param {{address1: string, address2: string, address3: string}} storeAddress - store address
 */
export const separateUncategorizedItems = (
  itemsWithoutShipment = [],
  storeAddress = {},
  isStoreOrder,
  isReturnOrder
) => {
  const uncategorizedItems = [];

  const compareBy = ['address1', 'address2', 'address3', 'address4'];

  for (let i = 0; i < itemsWithoutShipment.length; i++) {
    const item = itemsWithoutShipment[i];
    const shipToAddress = item?.shipTo?.address || {};
    // this is bugfix for STORE_ORDERS, which often don't have tracking info for some reason
    if (
      /**
       * If it's not a store order, then check if it's shipped (status includes 3700)
       * If it's shipped, but is in itemsWithoutShipment array, throw it to uncategorized
       */
      (!isStoreOrder && item.statuses?.some((status) => status.statusCode?.includes('3700'))) ||
      /**
       * If it's a store order and not a return order,
       * if there are field that are *not* the same and not empty,
       * the item is probably not shipped to the store
       */
      (isStoreOrder &&
        !isReturnOrder &&
        !compareBy.every(
          (key) =>
            storeAddress[key] === shipToAddress[key] || !shipToAddress[key] || !storeAddress[key]
        ))
    ) {
      itemsWithoutShipment.splice(itemsWithoutShipment.indexOf(item), 1);
      uncategorizedItems.push(item);
      // since splicing mutates an array we're iterating over, we should go back one item!
      i--;
    }
  }
  return uncategorizedItems;
};

/**
 * Let's us know if an EMEA order is a partner order and if so what partner it is from
 * @param {Object} order decorated response from orderDetail
 * @returns {'' | 
  'footlocker.digital.web',
  'perry.digital.web',
  'jdsports.digital.web',
  'prodirect.digital.web',
  'stadium.digital.web',
  'zalando.digital.web',
  'WHOLESALEEUROPE',
  'JDSPORTS',
  'ZALANDOEU',
  'PRODIRECT',
  'PERRY'
}
 */
export const getEmeaPartner = ({ omsRegionReference, channel }) => {
  if (omsRegionReference === Geo.EUROPE) {
    if (
      PartnersExcludedFromDetailsInEMEA.has(channel) ||
      channel === 'sportamore.digital.web' ||
      channel === 'unisport.digital.web'
    ) {
      return channel;
    }
  } else if (PartnersExcludedFromDetailsInEMEA.has(omsRegionReference)) {
    return omsRegionReference;
  }
  return '';
};

export const isJdSportsUkOrder = (orderDetail) => {
  const partner = getEmeaPartner(orderDetail);
  if (!partner) return false;
  return (
    (partner === Geo.JDSPORTS || partner === 'jdsports.digital.web') &&
    orderDetail?.orderLines?.every((ol) => ol.shipTo?.address?.country === 'GB')
  );
};

export const isStoreOrderReturn = (csOrderSummary) => {
  let storeOrderReturn = false;

  const orderSummaryObjects = getArrayProperty(csOrderSummary, 'objects');
  const salesOrderSummary = orderSummaryObjects?.find(
    (summary) => summary.orderType === 'SALES_ORDER'
  );
  storeOrderReturn = Boolean(salesOrderSummary?.channel?.includes('store'));

  return storeOrderReturn;
};
