import { Box, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import Typography from '@material-ui/core/Typography';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import React, { useContext, useState, useEffect } from 'react';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { convertArrayToString } from '../../../../utils/reactUtils.js';
import { step1SharedStyles } from '../sharedStyles';
import translations from './step1.i18n';
import { isVASOrderLineJerseyId, getOrderLineContainers } from '../../../../utils/order';
import { findKeyUsingValue, swapSpots } from '../../../../utils/dialog';
import useHasPermission from './../../../../hooks/useHasPermission';
import { ReturnGiftCard } from './../../../../constants/permissions.const';
import { Partners } from '../../../../constants/origin.const.js';
import Geo from '../../../../constants/geos.const';

/**
 * This component is step 1 of the Create Return flow
 *
 *
 * @param {Object} props – props object for a React component
 */
const Step1 = (props) => {
  const [orderDetail] = useContext(OrderContext);
  const [assets] = useContext(AssetsContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { i18nString } = useContext(NikeI18nContext);
  const { hasPermission } = useHasPermission();
  // Determines if an item should use the className associated with a primary item.
  const [frontRowMap, setFrontRowMap] = useState({});
  const { selectLine } = dialogActions;
  const {
    NON_RETURNABLE_ITEM,
    NON_RETURNABLE_VAS_ITEM,
    GIFT_CARD_VALUE,
    GIFT_CARD_BALANCE,
    NON_RETURNABLE_GIFT_CARD_ITEM,
    SIZE,
  } = mapValues(translations, i18nString);
  const { selectedLines } = dialogState;
  const classes = useStyles();

  let { orderLines, omsRegionReference } = orderDetail;
  orderLines = Array.from(orderLines);

  // Map of inline item lineNumbers to vas service lineNumbers and vice versa.
  const inlineVasLineNumberMap = {};

  /*
    Iterate through the orderLines and add them to an object where the value is a boolean that 
    represents whether an item is of a service type or not. This map will be used to determine
    which items will be in the 'front row' or 'back row' in the UI.
   */
  useEffect(() => {
    let newFrontRowMap = {};
    orderLines.forEach((line) => {
      if (line.orderLineType !== 'SERVICE') {
        newFrontRowMap[line.lineNumber] = true;
      } else {
        newFrontRowMap[line.lineNumber] = false;
      }
    });
    setFrontRowMap(newFrontRowMap);
  }, []);

  /**
   * Handles the onClick events for items that selectable.
   * @param {object} line orderLine item the action is being executed against.
   */
  const handleSelectLine = (line, isVasItem) => () => {
    if (frontRowMap[line.lineNumber]) {
      // Item is already in the front, so it is being selected.
      dialogDispatch(selectLine(line));
      // Check if item either is or has a VAS Jersey ID. If so, also select the corresponding item.
      if (line.hasNestedVasOrderLines && isVASOrderLineJerseyId(line.nestedVasOrderLines[0])) {
        dialogDispatch(selectLine(line.nestedVasOrderLines[0]));
      } else if (isVasItem && isVASOrderLineJerseyId(line)) {
        dialogDispatch(selectLine(line.parentOrderLine));
      }
    } else {
      // Item is not in the front and needs to be brought forth.
      const newFrontRowMap = swapSpots(
        frontRowMap,
        inlineVasLineNumberMap,
        line.lineNumber,
        isVasItem
      );
      setFrontRowMap(newFrontRowMap);
    }
  };

  /**
   * Handles the onClick events for items that are not selectable.
   * @param {string} lineNumber unique identifier of the orderline being chosen.
   * @param {boolean} isVasItem boolean representing if an item is a vas item.
   */
  const unselectableOnClick = (lineNumber, isVasItem) => {
    if (
      // Item is not a vas item and is not in the front and so we need to switch it.
      (!isVasItem && !frontRowMap[lineNumber]) ||
      // Item is vas and has a reference to another item so we need to switch it.
      (isVasItem && findKeyUsingValue(inlineVasLineNumberMap, lineNumber))
    ) {
      const newFrontRowMap = swapSpots(frontRowMap, inlineVasLineNumberMap, lineNumber, isVasItem);
      setFrontRowMap(newFrontRowMap);
    }
  };

  /**
   * Checks the orderline and
   *  - returns true
   *    a) if the orderline is not a gift card orderline
   *    b) if it is a giftcard orderline and if the athlete has
   *       permission to return gift cards
   * - returns false
   *    a) if the orderline is a giftcard and athlete does not
   *       have permission to return
   * @param {*} line - Orderline
   */
  const hasPermissionToSelectOrderLine = (line) => {
    if (line.styleNumber === 'GIFTCARD') {
      if (hasPermission(ReturnGiftCard, omsRegionReference)) {
        return true;
      }
      return false;
    }
    return true;
  };

  /**
   * Wraps each item in either a selectable or unselected card, depending
   * on the type of item it is and if it is returnable or not.
   *
   * @param {object} line orderLine item that needs to be wrapped in a card.
   * @param {string} i is the key/identifier to be used in the div component due
   *              to the grid layout.
   */
  const wrapEachCard = (line, i) => {
    const isVasItem = line.orderLineType === 'SERVICE';
    // determineGiftCardBalance(line);
    const key = `return-item-thumb-${i}`;
    // If item is not a vas item then it should be displayed in the first row.
    let cardClass = !isVasItem ? classes.firstRowItem : classes.secondRowItem;
    // If the item belongs in the front, attach the primaryItem styling.
    if (frontRowMap[line.lineNumber]) {
      cardClass = `${cardClass} ${classes.primaryItem}`;
    }
    // if item is returnable, we'll put up a selectable card for it
    const status = convertArrayToString(line.statuses);

    if (
      line?.omoboFlags?.isReturnable &&
      // because JD Sports is in US and EMEA, we add the other conditions to allow returns in EMEA
      (!Partners[orderDetail?.channel] ||
        omsRegionReference === Geo.JDSPORTS ||
        (orderDetail?.channel === 'jdsports.digital.web' && omsRegionReference === Geo.EUROPE)) &&
      !line.isGiftCardUsed &&
      hasPermissionToSelectOrderLine(line)
    ) {
      return (
        <div key={key} className={cardClass}>
          <div
            role='checkbox'
            aria-checked={Boolean(
              selectedLines[line.lineNumber] &&
                convertArrayToString(selectedLines[line.lineNumber].statuses) === status
            )}
            aria-label={line.item.itemDescription}
            onKeyPress={handleSelectLine(line, isVasItem)}
            onClick={handleSelectLine(line, isVasItem)}
            tabIndex={0}
            data-testid={`item-to-select-${i}`}>
            <Card
              className={
                selectedLines[line.lineNumber] &&
                convertArrayToString(selectedLines[line.lineNumber].statuses) === status
                  ? classes.selectedItem
                  : classes.item
              }
              elevation={selectedLines[line.lineNumber] ? 3 : 1}>
              <CardMedia
                className={classes.productThumb}
                image={getProductImageFromOrderLine(line, assets)}
                title={line.item.itemDescription}
                children={
                  selectedLines[line.lineNumber] &&
                  convertArrayToString(selectedLines[line.lineNumber].statuses) === status && (
                    <CheckIcon className={classes.productSelectCheckIcon} />
                  )
                }
              />
              <Box p={1}>
                <Typography variant='body1' noWrap gutterBottom>
                  {line.item.itemDescription}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Typography noWrap variant='caption'>
                    {line.styleNumber + '-' + line.colorCode}
                  </Typography>
                  <Typography noWrap variant='caption'>
                    {`${SIZE}: ${line.displaySize}`}
                  </Typography>
                </div>
                {line.styleNumber === 'GIFTCARD' && (
                  <>
                    <Typography className={classes.giftCardDetails} noWrap gutterBottom>
                      {GIFT_CARD_VALUE} - {line.giftCardValue}
                    </Typography>
                    <Typography className={classes.giftCardDetails}>
                      {GIFT_CARD_BALANCE} - {line.giftCardBalance?.balance}
                    </Typography>
                  </>
                )}
              </Box>
            </Card>
          </div>
        </div>
      );
    } else {
      // non-returnable items will be in an unselectable card with tooltip
      let toolTipMessage = '';
      if (isVasItem) {
        toolTipMessage = NON_RETURNABLE_VAS_ITEM;
      } else if (line.isGiftCardUsed) {
        toolTipMessage = NON_RETURNABLE_GIFT_CARD_ITEM;
      } else {
        toolTipMessage = NON_RETURNABLE_ITEM;
      }
      return (
        <div
          key={key}
          className={cardClass}
          role='checkbox'
          aria-checked={false}
          aria-disabled={true}
          aria-label={line.item.itemDescription}
          tabIndex={0}
          onKeyPress={() => unselectableOnClick(line.lineNumber, isVasItem)}
          onClick={() => unselectableOnClick(line.lineNumber, isVasItem)}>
          <Tooltip
            title={toolTipMessage}
            placement='bottom'
            enterDelay={200}
            leaveDelay={100}
            classes={{ tooltip: classes.tooltip }}>
            <Card className={classes.unselectableItem} elevation={0}>
              <CardMedia
                className={clsx(classes.productThumb, classes.disabledProductThumb)}
                image={getProductImageFromOrderLine(line, assets)}
                title={line.item.itemDescription}
              />
              <Box p={1}>
                <Typography variant='body1' className={classes.halfOpacity} gutterBottom>
                  {line.item.itemDescription}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Typography noWrap variant='caption'>
                    {line.styleNumber + '-' + line.colorCode}
                  </Typography>
                  <Typography noWrap variant='caption'>
                    {`${SIZE}: ${line.displaySize}`}
                  </Typography>
                </div>
                {line.styleNumber === 'GIFTCARD' && (
                  <>
                    <Typography className={classes.giftCardDetailsWithHalfOpacity}>
                      {GIFT_CARD_VALUE} - {line.giftCardValue}
                    </Typography>
                    <Typography className={classes.giftCardDetailsWithHalfOpacity}>
                      {GIFT_CARD_BALANCE} - {line.giftCardBalance?.balance}
                    </Typography>
                  </>
                )}
              </Box>
            </Card>
          </Tooltip>
        </div>
      );
    }
  };

  /**
   * Create a container with the appropriate className depending on whether it is a single item
   * or an item that has a VAS associated with it.
   * @param {string} key Unique identifer of the div
   * @param {React.Component} children Children objects to be displayed within this container.
   */
  const createContainer = (key, ...children) => {
    return (
      <div
        key={`'return-item-container-thumb-'${key}-container`}
        className={children.length > 1 ? classes.vasItemContainer : classes.singleItemContainer}>
        {children}
      </div>
    );
  };

  /**
   * Wraps each of the items that belong together. This will wrap vas items with their
   * item counterparts. This allows the vas items to be shown behind their counterparts
   * and to do the switching to make them visible when clicked.
   *
   * @param {array} orderLines list of orderline items.
   */
  const createOrderLineContainers = (orderLines, omsRegionReference) => {
    const orderLineContainers = getOrderLineContainers(orderLines, omsRegionReference);
    const wrappedOrderLineContainers = [];

    for (let i = 0; i < orderLineContainers.length; i++) {
      const container = orderLineContainers[i];
      const WrappedInlineItem = wrapEachCard(container.item, `${i}a`);

      if (container.service) {
        const wrappedServiceItem = wrapEachCard(container.service, `serviceItem${i}`);
        inlineVasLineNumberMap[container.item.lineNumber] = container.service.lineNumber;
        wrappedOrderLineContainers.push(createContainer(i, WrappedInlineItem, wrappedServiceItem));
      } else {
        // Inline item is standalone with no other references.
        wrappedOrderLineContainers.push(createContainer(i, WrappedInlineItem));
        // Added using an empty string to signal that there is no second row ui item.
        inlineVasLineNumberMap[container.item.lineNumber] = '';
      }
    }

    return wrappedOrderLineContainers;
  };

  const orderLineContainers = createOrderLineContainers(orderLines, omsRegionReference);

  return (
    <div className={classes.productGrid}>
      {orderLineContainers.map((line) => {
        return line;
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  ...step1SharedStyles(theme),
}));

export default Step1;
