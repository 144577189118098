/** React/Utils */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

/** Material-UI */
import { makeStyles } from '@material-ui/core/styles';

/** GraphQL */
import RETURN_CAPTURE_MUTATION from '../../../../mutations/returnCapture.mutation';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import RETURN_LOCATION_MUTATION from '../../../../mutations/returnLocation.mutation';
import ADDRESS_VALIDATION_V2_MUTATION from '../../../../mutations/addressValidatorV2.mutation';
// import { generateAccertifyReturnRequestPayload } from './requestPayloads/accertifyPayLoads';
// import ACCERTIFY_RETURN_REQUEST_MUTATION from '../../../../mutations/accertifyReturn.mutation';

/** Local */
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { PermissionContext } from '../../../../store/contexts/permissionContext';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import useHasPermission from './../../../../hooks/useHasPermission';

import translations from '../dialog.i18n';
import {
  addressHasRequiredFields,
  areReasonsMissingFrom,
  isQuantityMissingForLineItems,
} from '../../../../utils/dialog';
import { stepControlSharedStyles } from '../sharedStyles';
import {
  removeNullValuesFromObject,
  removeTypeNameFromObject,
} from './../../../../utils/reactUtils';
import getOrderRoutes from '../../../../routes';
import useSnacks from '../../../../hooks/useSnacks';
import {
  generateAddressValidationPayload,
  generateReturnCapturePayload,
  generateReturnLocationPayload,
} from './requestPayloads/payloads.KR';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';
import { normalizeAddress } from '../../../../utils/address';
import AddressValidator from './../../../shared/addressValidator';
import {
  DialogTypes,
  ResponseStatuses,
  ApiTimeOut,
  TimeOutErrorMessageFromGrand,
  AddressValidationStatus,
  RetryMaxCount,
} from '../../../../constants/dialog.const';
import { ShippingOptions } from '../../../../constants/shippingOptions.const';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { NextButton, BackButton, SubmitReturnCaptureButton } from '../shared/buttons';
import KRProvincesConst from '../../../../constants/address/KRProvinces.const';
import { addressChangeLogging } from '../../../../utils/address';
import { Granted } from '../../../../constants/permissions.const';
import { ByPassAddressValidation } from '../../../../constants/permissions.const';
/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
function StepControl() {
  const classes = useStyles();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);
  const [consumerState] = useContext(ConsumerContext);
  const [, setOrderDetails] = useContext(OrderContext);
  const [, hasModificationPermissions] = useContext(PermissionContext);
  const [queryDetailsCounter, setQueryDetailsCounter] = useState(1);
  const { setSlowLoading, setSnack, setError, getLoadingStatus } = useSnacks();
  const [receivingNode, setReceivingNode] = useState('');
  const [standardCarrierAlphaCode, setStandardCarrierAlphaCode] = useState('');
  const [queryDetailsSuccessCounter, setQueryDetailsSuccessCounter] = useState(0);
  const [athleteInfo] = useContext(AthleteContext);
  const setRoute = useHistoryPushWithSessionId();
  const routes = getOrderRoutes();
  const [addressToValidate, setAddressToValidate] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [addressFromAddressValidation, setAddressFromAddressValidation] = useState('');
  const [i18State] = useContext(I18nContext);
  const { NON_PICKUP } = ShippingOptions;
  const { hasPermission } = useHasPermission();

  const {
    prevStep,
    nextStep,
    reset,
    setReturnOrderNumber,
    setShipToAddress,
    setHasTimedOut,
    setValidateAddress,
    open,
  } = dialogActions;

  const {
    selectedLines,
    activeStep,
    returnOrderNumber,
    lock,
    hasTimedOut,
    address,
    validateAddress,
    hasUserEditedAddress,
  } = dialogState;

  const {
    RETURN_CREATION_FAILED,
    RETURN_ORDER_CREATED,
    RETURN_ORDER_NOT_AVAILABLE,
    RETURN_LOCATION_FAILED,
    CREATE_RETURN_TIME_OUT_ERROR_MESSAGE,
    ADDRESS_VALIDATION_ERROR,
  } = useMemoTranslations(translations);
  const shouldSubmitOnCurrentStep = activeStep === 2;

  /**
   * Determines whether the next button, which are displayed on non-submission pages,
   * should be disabled or not based on validations.
   */
  const shouldNextButtonBeDisabled = () => {
    switch (activeStep) {
      case 0:
        return selectedItemKeys.length === 0;
      case 1:
        return (
          areReasonsMissingFrom(selectedLines, DialogTypes.RETURN) ||
          isQuantityMissingForLineItems(selectedLines, DialogTypes.RETURN)
        );
      default:
        return true;
    }
  };

  /**
   * Function that calls address validation api
   */
  const handleAddressValidation = () => {
    setAddressToValidate(address);
    const addressValidationPayload = generateAddressValidationPayload(
      address,
      orderDetail.locale,
      i18State.language
    );

    doAddressValidation({
      variables: {
        input: addressValidationPayload,
        guid: uuidv4(),
      },
    });
  };

  // Object that contains address entered in the form and suggested address from service
  const suggestedAndEnteredAddress = {
    suggestedAddress: removeTypeNameFromObject(addressFromAddressValidation),
    addressEntered: addressToValidate,
  };

  /**
   * Updates url to match new return order if return order has been successfully made.
   */
  const updateUrlForNewReturn = () => setRoute(`/order/${returnOrderNumber}/${routes[0]}`);

  /**
   * Creates a query used to retrieve the OrderDetails that can be executed at a
   * different time. This is used for the retry calls in the event that the return
   * order has been created, but is not yet retrievable.
   */
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: queryOrderDetailsError, stopPolling },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 1500,
    onError: () => {
      const isOrderDetail404 =
        queryOrderDetailsError?.message?.includes('404') &&
        /* 
          if the 404 were coming from a nested/stitched query, "path" would include nested 
          data nodes (e.g. ["orderDetail", "shipmentsV2"]), which is why we ensure the path
          only includes "orderDetail"
        */
        queryOrderDetailsError?.path?.toString() === 'orderDetail';
      /*
        If the error we receive is _not_ a 404 and we have received partial data
        then we have received order detail data for the newly created return order,
        and we should stop polling, update order details in state, and direct the user
        to the order details page for the new return order.
      */
      if (!isOrderDetail404 && queryOrderDetailsData?.orderDetail) {
        stopPolling();
        setOrderDetails(queryOrderDetailsData?.orderDetail);
        dispatchReset();
        setSnack(RETURN_ORDER_CREATED);
        updateUrlForNewReturn();
      }
      /*
          If the error we receive _is_ a 404 and/or we do not have partial data,
          then we allow polling to continue, but we tally our polls so that we may 
          cut our losses once 10 requests have been made and failed.
        */
      if (queryDetailsCounter === 10) {
        setError(RETURN_ORDER_NOT_AVAILABLE);
        dispatchReset();
        stopPolling();
      } else {
        setQueryDetailsCounter(queryDetailsCounter + 1);
      }
    },
    onCompleted: () => {
      const setReturnOrderSuccess = () => {
        // Set Order details on successful return creation
        setOrderDetails(queryOrderDetailsData.orderDetail);
        dispatchReset();
        setSnack(RETURN_ORDER_CREATED);
        updateUrlForNewReturn();
      };
      /* If call is successful without errors and orderheaderkey 
      is available, we stop making retry calls */
      if (queryOrderDetailsData?.orderDetail?.orderHeaderKey) {
        stopPolling();
        setReturnOrderSuccess();
        return;
      }
      if (queryDetailsSuccessCounter >= RetryMaxCount) {
        stopPolling();
        setReturnOrderSuccess();
      } else {
        setQueryDetailsSuccessCounter(queryDetailsSuccessCounter + 1);
      }
    },
  });

  const [doAddressValidation] = useMutation(ADDRESS_VALIDATION_V2_MUTATION, {
    onError: (err) => {
      // If user has by pass address validation permission, then we skip address validation

      if (hasPermission(ByPassAddressValidation, orderDetail.omsRegionReference)) {
        if (shouldSubmitOnCurrentStep) {
          handleReturnOrderSubmit();
          return;
        }
      } else setError(`${ADDRESS_VALIDATION_ERROR} ${err.message}`);
    },
    onCompleted: (data) => {
      const addressFromResponse = normalizeAddress(data.addressValidatorV2.address);
      const status = data.addressValidatorV2.verificationCode;
      const score = data.addressValidatorV2.score;
      removeNullValuesFromObject(addressFromResponse);
      setVerificationCode(status);
      setAddressFromAddressValidation(addressFromResponse);
      /*
       * Show address validation component only for
       * a) Addresses that are not in verified status
       * b) Verified address with score less than 95
       * For verified address if the step is return creation step
       * then submit return or move to next step
       */
      if (status === AddressValidationStatus.VERIFIED && score >= 95 && shouldSubmitOnCurrentStep) {
        handleReturnOrderSubmit();
      } else {
        dialogDispatch(setValidateAddress(true));
      }
    },
  });

  // Generate the function to execute the return location mutation
  // TODO: fix when this is working in test
  const [submitReturnLocation] = useMutation(RETURN_LOCATION_MUTATION, {
    onError: (err) => {
      setError(RETURN_LOCATION_FAILED);
    },
    onCompleted: (response) => {
      setReceivingNode(response?.returnLocation?.receivingNode);
      setStandardCarrierAlphaCode(response?.returnLocation?.standardCarrierAlphaCode);

      // Update the shipTo field for any order except those from NIKEEUROPE.
      const { address, recipient, contactInformation } = response.returnLocation.shipTo;
      const shipTo = {
        address: {
          ...removeTypeNameFromObject(address),
        },
        company: recipient.company,
        contactInformation: {
          dayPhoneNumber: contactInformation.dayPhoneNumber,
        },
      };

      // Historically, the state value returned from the service has not been in the KR-XX format.
      const untranslatedKrStateValue = shipTo.address.state;
      if (!KRProvincesConst.kr[untranslatedKrStateValue]) {
        const krList = Object.values(KRProvincesConst.kr);
        const result = krList.find((el) => el.name === untranslatedKrStateValue);
        if (result) {
          shipTo.address.state = result.abbreviation;
        }
      }

      dialogDispatch(setShipToAddress(shipTo));
    },
  });

  // const [submitAccertifyRequest] = useMutation(ACCERTIFY_RETURN_REQUEST_MUTATION, {
  //   onError: (err) => {
  //     console.error('Accertify Return Request Error', err);
  //   },
  //   onCompleted: (response) => {
  //     console.log('Accertify Return Request Response', response);
  //   },
  // });

  // Generate the function to execute the return captures mutation.
  const [submitReturnOrder, { loading: returnLoading }] = useMutation(RETURN_CAPTURE_MUTATION, {
    onError: (err) => {
      // Logging the error message so we do not lose track of it.
      console.error('Return Capture Error', err);
      if (err.message === TimeOutErrorMessageFromGrand) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else {
        /*
         * If the return order submission fails, we want to display a readable error
         * and go back to the order screen.
         */
        setError(RETURN_CREATION_FAILED);
      }
      dispatchReset();
    },
    onCompleted: (response) => {
      const { createReturn } = response;
      if ((createReturn.status = ResponseStatuses.COMPLETED && createReturn.response)) {
        if (createReturn.response.returnOrderNumber) {
          dialogDispatch(setReturnOrderNumber(createReturn.response.returnOrderNumber));
          // if display is locked, reset dialog, otherwise query order details + close dialog
          if (lock) {
            dialogDispatch(open(DialogTypes.ACTION_COMPLETE, true));
            setSnack(RETURN_ORDER_CREATED);
          } else {
            queryOrderDetails({
              variables: {
                orderNumber: createReturn.response.returnOrderNumber,
                isFollowUp: true,
              },
            });
          }
        }
      } else if (
        createReturn.status === ResponseStatuses.IN_PROGRESS ||
        createReturn.status === ResponseStatuses.PENDING
      ) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (createReturn.error) {
        const errorMessageOnJobCompletion = `${createReturn.error.httpStatus}: ${createReturn.error.message}`;
        setError(errorMessageOnJobCompletion);
        dispatchReset();
      }
    },
  });

  // Need to wait for shipTo address to be populated in state before we can request a return
  useEffect(() => {
    if (dialogState?.shipTo?.recipient?.companyName) {
      // Loop through the selected lines for return and make accertify call for each line separately
      // commenting out the accertify call as requested by the team temporarily
      // Object.keys(dialogState.selectedLines).forEach((key) => {
      //   const selectedLine = dialogState.selectedLines[key];
      //   const accertifyRequestPayLoad = generateAccertifyReturnRequestPayload(
      //     dialogState,
      //     selectedLine,
      //     orderDetail,
      //     athleteInfo
      //   );
      //   // For phase 1 as we are not going to take any action on the accertify response we
      //   // just make parallel calls and leave it.
      //   submitAccertifyRequest({ variables: { input: accertifyRequestPayLoad } });
      // });
      const returnCapturesInput = generateReturnCapturePayload(
        dialogState,
        orderDetail,
        receivingNode,
        standardCarrierAlphaCode,
        athleteInfo
      );

      submitReturnOrder({
        variables: {
          input: returnCapturesInput,
          region: orderDetail.omsRegionReference,
          timeout: ApiTimeOut,
        },
      });

      if (hasModificationPermissions === Granted && hasUserEditedAddress) {
        addressChangeLogging(
          selectedLines,
          address,
          orderDetail.omsRegionReference,
          orderDetail.orderNumber,
          athleteInfo.email
        );
      }
    }
  }, [dialogState?.shipTo?.recipient?.companyName]);

  const dispatchReset = () => {
    !lock && dialogDispatch(reset());
  };

  /*
   * Determines if we are on a submission step,
   * NOTE: we are not using the SubmissionSteps const for Korea,
   * because it does not match the normal Return Dialog pattern
   */
  const determineIfSubmissionStep = () => Boolean(activeStep === 2);

  const isSubmissionStep = determineIfSubmissionStep();
  const selectedItemKeys = Object.keys(selectedLines);
  const isSubmissionLoading = getLoadingStatus() || returnLoading;

  /**
   * This function will determine whether to call address validation or directly
   * submit returns with out address validation
   */
  const determineReturnSubmissionPath = () => {
    isAddressValidationRequired ? handleAddressValidation() : handleReturnOrderSubmit();
  };

  /*
    Determines whether address validation is required based on the step number as well
    as if the shippingOption is set to 'nonPickup', which is used by warehouse workers
    to indicate that the item being returned has already been returned and address at
    this point is not important and just needed for the api to not break. 
  */
  const isAddressValidationRequired = activeStep === 2 && dialogState.shippingOption !== NON_PICKUP;

  /**
   * Handles the submission of the createReturn mutation.
   */
  function handleReturnOrderSubmit() {
    const returnLocationInput = generateReturnLocationPayload(
      dialogState,
      consumerState,
      orderDetail
    );

    setSlowLoading();

    submitReturnLocation({ variables: { input: returnLocationInput } });

    /*
     * We need to wait for state to update - see useEffect for the submitReturnOrder call
     * which watches for dialogueState.shipTo address to update
     */
  }

  const isSubmissionDisabled =
    hasTimedOut ||
    isSubmissionLoading ||
    areReasonsMissingFrom(selectedLines, DialogTypes.RETURN) ||
    isQuantityMissingForLineItems(selectedLines, DialogTypes.RETURN) ||
    !addressHasRequiredFields(orderDetail.omsRegionReference, address);

  const handleNext = () => dialogDispatch(nextStep());

  if (!isSubmissionStep) {
    return (
      <div className={classes.actionsContainer}>
        <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
        <NextButton disabled={shouldNextButtonBeDisabled()} onClick={handleNext} />
      </div>
    );
  } else {
    return (
      <>
        <div className={classes.actionsContainer}>
          <BackButton disabled={activeStep === 0} onClick={() => dialogDispatch(prevStep())} />
          <SubmitReturnCaptureButton
            disabled={isSubmissionDisabled}
            onClick={determineReturnSubmissionPath}
          />
        </div>
        {/* Display address validator component based on address validator api response */
        validateAddress && (
          <AddressValidator
            address={suggestedAndEnteredAddress}
            status={verificationCode}
            createReturnOrder={handleReturnOrderSubmit}
            endOnActiveStep2={true}
          />
        )}
      </>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));

export default withRouter(StepControl);
