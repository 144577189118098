const translations = {
  FREE_SHIPPING: {
    description: 'explanation of what shipGroupSg1 grants the customer',
    primaryValue: 'This will grant free shipping to the customer.',
    stringKey: 'freeShipping',
  },
  SELECT_DISCOUNT: {
    description: 'default value of dropdown menu to select discount',
    primaryValue: 'Select Discount',
    stringKey: 'selectDiscount',
  },
  SHIP_GROUP: {
    description: 'label for the type of shipping group',
    primaryValue: 'Ship Group',
    stringKey: 'shipGroup',
  },
  SHIPPING_DISCOUNT_REASON: {
    description: 'label for dropdown menu to select a reason',
    primaryValue: 'Reason for shipping discount',
    stringKey: 'shippingDiscountReason',
  },
  SUBMIT_SHIPPING_DISCOUNT: {
    description: 'button label to submit a shipping discount',
    primaryValue: 'Submit Shipping Discount',
    stringKey: 'submitShippingDiscount',
  },
  SHIPPING_DISCOUNT_TIME_OUT_ERROR_MESSAGE: {
    stringKey: 'shippingDiscountTimeoutErrorMessage',
    primaryValue:
      'Shipping Discount is still pending. Please return to SwooshDesk and check back after some time',
    description: 'Error message when shipping discount call times out',
  },
  SHIPPING_DISCOUNT: {
    description: 'Text needed in various shipping discount messages',
    primaryValue: 'Shipping Discount',
    stringKey: 'shippingDiscount',
  },
  SUCCESS: {
    description: 'label for alerting the user that an action was successful',
    primaryValue: 'successful!',
    stringKey: 'dialogSuccess',
  },
  ERROR: {
    description: 'label for alerting the user that an action was unsuccessful',
    primaryValue: 'unsuccessful. Please try again!',
    stringKey: 'dialogError',
  },
  SHIPPING_DISCOUNT_SUBMITTED_MESSAGE: {
    stringKey: 'shippingDiscountSubmittedMessage',
    primaryValue:
      'Request to apply shipping discount has been submitted and will be processed shortly',
    description: 'message when shipping discount is submitted successfully and is being processed',
  },
};

export default translations;
