module.exports = [
  {
    name: 'New South Wales',
    label: 'New South Wales',
    abbreviation: 'AU-NSW',
  },
  {
    name: 'Victoria',
    label: 'Victoria',
    abbreviation: 'AU-VIC',
  },
  {
    name: 'Queensland',
    label: 'Queensland',
    abbreviation: 'AU-QLD',
  },
  {
    name: 'Tasmania',
    label: 'Tasmania',
    abbreviation: 'AU-TAS',
  },
  {
    name: 'South Australia',
    label: 'South Australia',
    abbreviation: 'AU-SA',
  },
  {
    name: 'Western Australia',
    label: 'Western Australia',
    abbreviation: 'AU-WA',
  },
  {
    name: 'Northern Territory',
    label: 'Northern Territory',
    abbreviation: 'AU-NT',
  },
  {
    name: 'Australian Capital Territory',
    label: 'Australian Capital Territory',
    abbreviation: 'AU-ACT',
  },
];
